import styled, { css } from 'styled-components'

import { applyModifiers } from 'common/functions'
import { spacers } from 'common/constants'
import { breaks } from 'common/styles/settings'
import { Modifiers as Mods } from 'common/styles/modifiers'

const {
  position: { absoluteFull },
  display: { flex },
  justifyContent: { spaceBetween }
} = Mods

export type T_StageElement = keyof typeof Modifiers
export interface I_StageElement {
  order: number
  modifiers?: T_StageElement[]
}

const Modifiers = {
  spaceBetween: () => css`
    ${flex}
    ${spaceBetween}
    flex-direction: row;

    &:after {
      content: initial;
      background-color: transparent !important;
    }
  `,
  paddingOnSides: () => css`
    padding-left: ${spacers.l};
    padding-right: ${spacers.l};

    ${breaks.md.up} {
      padding-left: ${spacers.xl};
      padding-right: ${spacers.xl};
    }

    ${breaks.xl.up} {
      padding-left: ${spacers.xxl};
      padding-right: ${spacers.xxl};
    }
  `
}

export const StageElement = styled.div<I_StageElement>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  height: inherit;

  ${(props) => applyModifiers(Modifiers, props.modifiers)}

  &:after {
    ${absoluteFull}
    content: '';
    background-color: black;
    opacity: 0.24;
  }
`
