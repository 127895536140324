import React from 'react'
import { I_Story } from 'common/queries/allStories'
import { allStoriesQuery } from 'common/queries'
import { convertStoryContent } from 'hooks'

import { Container } from 'components/Container'
import { Tile } from './Tile'

interface TilesProps {
  blok: {
    links: {
      link: string
    }[]
  }
}

export const Tiles: React.FC<TilesProps> = ({ blok }) => {
  let exhibitions: I_Story[] = []
  const { links } = blok
  const {
    allStoryblokEntry: { stories }
  } = allStoriesQuery()

  links.map((link) => {
    const hit = stories.find(({ story }) => {
      return story.uuid === link.link
    })
    if (hit) {
      const converted = convertStoryContent(hit.story)
      hit.story.content = converted.content
      exhibitions.push(hit)
    }
  })

  const renderTile = (item: I_Story) => {
    const id = item.story.uuid
    const slug = item.story.fullSlug
    const isArchived = item.story.content.isArchived
    const isCurrentExhibition = item.story.content.isCurrentExhibition
    const { image, headline, artist, duration } = item.story.content.body[0]

    return Tile({ headline, artist, duration, image, slug, id, isArchived, isCurrentExhibition })
  }

  return (
    <Container
      modifiers={['flex', 'flexWrap', 'spaceBetween', 'spacingTop', 'spacingBottom']}
      spacingTop={'xxxl'}
      spacingBottom={'xl'}
      isFluid={false}
    >
      {exhibitions.map((item) => renderTile(item))}
    </Container>
  )
}
