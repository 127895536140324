import React from 'react'
import { motion as m } from 'framer-motion'
import { animation as a } from './animation'

interface MainLayoutProps {
  children: React.ReactNode
  location: Location
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children, location }) => {
  return (
    <m.div
      style={{ position: 'relative' }}
      key={location.pathname}
      initial={'initial'}
      animate={'in'}
      exit={'exit'}
      variants={a.layout}
    >
      {children}
    </m.div>
  )
}
