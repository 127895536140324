import styled from 'styled-components'
import { motion as m } from 'framer-motion'
import { gaps } from 'common/styles/settings'
import { pxToRem } from 'common/functions'

export const Teaser = styled(m.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding-top: ${gaps.xxxl};
  padding-bottom: ${gaps.xxxl};
  height: 70vh;
  min-height: ${pxToRem(400)};
  cursor: pointer;
`
