import styled from 'styled-components'

import { breaks } from 'common/styles/settings'
import { pxToRem } from 'common/functions'

export const MenuRow = styled.div`
  display: flex;
  flex-direction: column;

  height: 55vh;
  min-height: ${pxToRem(320)};

  ${breaks.sm.up} {
    flex-direction: row;

    height: initial;
    min-height: initial;
  }
`
