import React from 'react'
import { VimeoStyles as V } from './styles'

interface Props {
  src: string
}

export const Vimeo: React.FC<Props> = ({ src }) => {
  return (
    <V.Item>
      <V.Vimeo.Iframe
        src={src}
        width="640"
        height="338"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    </V.Item>
  )
}
