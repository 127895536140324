import React from 'react'
import { ButtonStyles as B } from './styles'
import { T_Button } from './styles/Button'

interface ButtonProps {
  children: React.ReactNode
  style?: React.CSSProperties
  modifiers?: T_Button[]
  delegateOnClick: () => void
}

export const Button: React.FC<ButtonProps> = (props) => {
  const { children, modifiers, style, delegateOnClick } = props

  return (
    <B.Button style={style} modifiers={modifiers} onClick={() => delegateOnClick()}>
      {children}
    </B.Button>
  )
}
