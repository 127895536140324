import { graphql, useStaticQuery } from 'gatsby'

export interface I_StoryEntry {
  id: string
  content: any
  fullSlug: string
  lang: string
  uuid: string
}

export interface I_Story {
  story: I_StoryEntry
}

export interface I_AllStoriesQuery {
  allStoryblokEntry: {
    stories: I_Story[]
  }
}

export function allStoriesQuery(): I_AllStoriesQuery {
  return useStaticQuery(graphql`
    query AllStories {
      allStoryblokEntry {
        stories: edges {
          story: node {
            id
            lang
            uuid
            fullSlug: full_slug
            content
          }
        }
      }
    }
  `)
}
