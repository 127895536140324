import styled from 'styled-components'
import { pxToRem } from 'common/functions'

export const StageTimeline = styled.div`
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: ${pxToRem(2)};
  background-color: white;
  overflow: hidden;
`
