import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import { AnimatePresence, motion as m } from 'framer-motion'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import { I_Story } from 'common/queries/allStories'
import { navigationQuery, allStoriesQuery } from 'common/queries'
import { convertStoryContent, sbClient } from 'hooks'

import { Container } from 'components/Container'
import { Text } from 'components/Text'

import { MenuStyles as M } from './styles'
import { animation as a } from './animation'

interface MenuProps {}

export const Menu: React.FC<MenuProps> = () => {
  const refMenuInner = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { storyblokEntry } = navigationQuery()
  const {
    allStoryblokEntry: { stories }
  } = allStoriesQuery()

  const { content } = convertStoryContent(storyblokEntry)

  let hits: I_Story[] = []

  content.Links.map((link: string) => {
    const hit = stories.find(({ story }) => story.uuid === link)

    if (hit) {
      const converted = convertStoryContent(hit.story)
      hit.story.content = converted.content
      hits.push(hit)
    }
  })

  useEffect(() => {
    refMenuInner.current && isOpen ? disableBodyScroll(refMenuInner.current) : clearAllBodyScrollLocks()
  }, [isOpen, refMenuInner])

  const renderNavigation = (title: string, fullSlug: string, index: number) => (
    <m.div key={index} custom={index + 1} initial={'out'} animate={'in'} exit={'out'} variants={a.paneLink}>
      <Link to={`/${fullSlug}`} onClick={() => setIsOpen(!isOpen)}>
        <Text type={'span'} modifiers={['block', 'tertiary', 'textTransform', 'spacingBottom']} spacingBottom={'xl'}>
          {title}
        </Text>
      </Link>
    </m.div>
  )

  const renderAddress = () => {
    const { address, openingHours } = content
    const html = sbClient.richTextResolver.render(address)
    const htmlO = sbClient.richTextResolver.render(openingHours)

    return (
      <M.Column modifiers={['contentSplit']}>
        <M.Information style={{ lineHeight: '1.75' }} dangerouslySetInnerHTML={{ __html: html }} />
        <M.Information style={{ lineHeight: '1.75' }} dangerouslySetInnerHTML={{ __html: htmlO }} />
      </M.Column>
    )
  }

  return (
    <>
      <M.Menu onClick={() => setIsOpen(!isOpen)}>
        <M.Bar />
        <M.Bar />
        <M.Svg.Item xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86.49 86.49">
          <M.Svg.Circle modifiers={['secondary']} d="M43.25.25a43,43,0,1,1-43,43,43,43,0,0,1,43-43" />
          <M.Svg.Circle
            initial={'hidden'}
            whileHover={'visible'}
            variants={a.circle}
            d="M43.25.25a43,43,0,1,1-43,43,43,43,0,0,1,43-43"
          />
        </M.Svg.Item>
      </M.Menu>
      <AnimatePresence exitBeforeEnter>
        {isOpen && (
          <M.Pane.Item ref={refMenuInner} initial={'out'} animate={'in'} exit={'out'} variants={a.pane}>
            <M.Pane.Inner>
              <Container
                modifiers={['noPadding', 'spacingTop', 'spacingBottom']}
                spacingTop={'l'}
                spacingBottom={'l'}
                fluid={true}
              >
                <M.Row>
                  <M.Column modifiers={['main']}>
                    {hits.map((hit, index) =>
                      renderNavigation(hit.story.content.Meta.title, hit.story.fullSlug, index)
                    )}
                  </M.Column>
                  {renderAddress()}
                </M.Row>
              </Container>
            </M.Pane.Inner>
          </M.Pane.Item>
        )}
      </AnimatePresence>
    </>
  )
}
