import React from 'react'

import { Text } from 'components/Text'
import { TagStyles as T, T_Tag } from './styles'

interface TagProps {
  title: string
  modifiers?: T_Tag[]
}

export const Tag: React.FC<TagProps> = ({ title, modifiers }) => {
  return (
    <T.Tag modifiers={modifiers}>
      <Text modifiers={['xSmall']}>{title}</Text>
    </T.Tag>
  )
}
