import styled from 'styled-components'

import { pxToRem } from 'common/functions'
import { Modifiers as Mods } from 'common/styles/modifiers'
import { breaks } from 'common/styles/settings'

const {
  display: { flex },
  justifyContent: { justifyCenter },
  alignItems: { alignCenter }
} = Mods

export const Menu = styled.div`
  ${flex}
  ${justifyCenter}
  ${alignCenter}

  flex-direction: column;
  position: relative;
  z-index: 11;

  width: ${pxToRem(32)};
  height: ${pxToRem(32)};

  ${breaks.lg.up} {
    width: ${pxToRem(48)};
    height: ${pxToRem(48)};
  }

  cursor: pointer;

  cursor: pointer;
`
