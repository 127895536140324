import React from 'react'
import { navigate } from 'gatsby-link'

import { convertStoryContent } from 'hooks/'
import { allStoriesQuery } from 'common/queries'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Icon } from 'components/Icon'
import { Text } from 'components/Text'
import { T_TextModifiers } from 'components/Text/styles/TextModifiers'

import { TeaserStyles as T } from './styles'
import { ButtonStyles as BTN } from 'components/Button/styles'
import { animation } from './animation'
import { motion } from 'framer-motion'

interface TeaserProps {
  blok: {
    link: string
  }
}

export const Teaser: React.FC<TeaserProps> = ({ blok }) => {
  const { link } = blok
  const {
    allStoryblokEntry: { stories }
  } = allStoriesQuery()

  const storyPage = stories.find(({ story }) => link === story.uuid)

  if (!storyPage) {
    return null
  }

  const story = convertStoryContent(storyPage.story) as { fullSlug: string; content: { body: any[] } }
  const { fullSlug } = story
  const { headline, artist, duration, image } = story.content.body[0]

  const renderButton = () => (
    <Button delegateOnClick={() => {}} modifiers={['round', 'outlineInverted', 'fillTransparent']}>
      <BTN.Label>
        <Icon type={'arrow-right'} />
      </BTN.Label>
    </Button>
  )

  const sharedStyles: T_TextModifiers[] = ['textTransform', 'colorInverted']

  const el = {
    paragraph: Text({
      type: 'h4',
      modifiers: sharedStyles.concat(['paragraph', 'spacingBottom']),
      spacingBottom: 'l',
      children: duration
    }),
    headline: Text({
      type: 'h3',
      modifiers: sharedStyles.concat(['headline']),
      children: (
        <a href={`/${fullSlug}`} title={`Next Page ${headline}`}>
          {headline}
          <br />
        </a>
      )
    }),
    subline: Text({
      type: 'h4',
      modifiers: sharedStyles.concat(['headline', 'inlineBlock', 'spacingBottom']),
      spacingBottom: 'l',
      children: artist
    })
  }

  return (
    <T.Teaser initial={'out'} whileHover={`in`} variants={animation.teaser} onClick={() => navigate(`/${fullSlug}`)}>
      <T.Content>
        <Container modifiers={['spacingTop', 'spacingBottom']} spacingTop={'xxl'} spacingBottom={'xxl'} isFluid={false}>
          <motion.div variants={animation.subline}>{el.paragraph}</motion.div>
          <motion.div variants={animation.headline}>{el.headline}</motion.div>
          <motion.div variants={animation.name} style={{ opacity: 0.48 }}>
            {el.subline}
          </motion.div>
          {renderButton()}
        </Container>
      </T.Content>
      <T.ImageWrapper>
        <T.Image src={`${image.filename}/m/filters:quality(80)`} alt={`${image.alt}`} />
      </T.ImageWrapper>
    </T.Teaser>
  )
}
