import styled, { css } from 'styled-components'
import { applyModifiers } from 'common/functions'
import { breaks } from 'common/styles/settings'

type T_MenuColumn = keyof typeof Modifiers
interface I_MenuColumn {
  modifiers?: T_MenuColumn[]
}

const Modifiers = {
  main: () => css`
    flex: 1;
  `,
  contentSplit: () => css`
    ${breaks.sm.down} {
      flex-direction: row;
    }
  `
}

export const MenuColumn = styled.div<I_MenuColumn>`
  display: flex;
  flex: 0;
  flex-direction: column;

  ${breaks.sm.down} {
    flex: 1;
  }

  ${({ modifiers }) => applyModifiers(Modifiers, modifiers)}
`
