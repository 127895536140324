import React from 'react'

import { I_Image } from 'common/types'
import { sbClient } from 'hooks'
import { Container } from 'components/Container'
import { TieStyles as T } from './styles'

interface Props {
  blok: {
    text: any
    images: I_Image[]
  }
}

export const Tie: React.FC<Props> = ({ blok }) => {
  const { text, images } = blok
  const html = sbClient.richTextResolver.render(text)

  const renderImage = (image: I_Image) => {
    return <T.Image src={image.filename} title={image.alt} />
  }

  return (
    <div>
      <Container isFluid={false} modifiers={['spacingTop', 'spacingBottom']} spacingTop={'xl'} spacingBottom={'xl'}>
        <T.Block>
          <T.Content dangerouslySetInnerHTML={{ __html: html }} />
          {images && <div>{images.map((image) => renderImage(image))}</div>}
        </T.Block>
      </Container>
    </div>
  )
}
