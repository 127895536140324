import styled, { css } from 'styled-components'
import { motion as m } from 'framer-motion'
import { gaps } from 'common/styles/settings'
import { pxToRem } from 'common/functions'
import { Modifiers as Mods } from 'common/styles/modifiers'

import { AboutCover as Cover } from './AboutCover'
import { AboutWrapper as Wrapper } from './AboutWrapper'

const {
  font: {
    sizes: { body }
  }
} = Mods

const About = styled(m.div)`
  overflow: hidden;

  & p {
    ${body}
    max-width: ${pxToRem(800)};
  }

  & p + p {
    padding-top: ${gaps.xl};
  }
`

export const AboutStyles = {
  About,
  Cover,
  Wrapper
}
