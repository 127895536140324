import styled from 'styled-components'
import { motion as m } from 'framer-motion'

export const MenuPane = styled(m.div)`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: whitesmoke;
`
