import { Teaser } from './Teaser'
import { TeaserContent as Content } from './TeaserContent'
import { TeaserImage as Image } from './TeaserImage'
import { TeaserImageWrapper as ImageWrapper } from './TeaserImageWrapper'

export const TeaserStyles = {
  Teaser,
  Content,
  Image,
  ImageWrapper
}
