import React from 'react'

import { About } from '../About'
import { Hero } from '../Hero'
import { Images } from '../Images'
import { Tiles } from '../Tiles'
import { Stage } from '../Stage'
import { Teaser } from '../Teaser'
import { Tie } from '../Tie'

const Components: { [key: string]: any } = {
  about: About,
  hero: Hero,
  images: Images,
  stage: Stage,
  teaser: Teaser,
  tiles: Tiles,
  tie: Tie
}

export interface DynamicComponentProps {
  blok: any
  lang: string
  location: Location
  isCurrentExhibition: boolean
}

export const DynamicComponent: React.FC<DynamicComponentProps> = ({ blok, lang, location, isCurrentExhibition }) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    return (
      <Component
        isCurrentExhibition={isCurrentExhibition}
        blok={{ ...blok, isCurrentExhibition }}
        key={blok._uid}
        lang={lang}
        location={location}
      />
    )
  }

  return blok.component ? <div></div> : null
}
