import { Menu } from './Menu'
import { MenuPane as Pane } from './MenuPane'
import { MenuPaneInner as Inner } from './MenuPaneInner'
import { MenuBar as Bar } from './MenuBar'
import { MenuColumn as Column } from './MenuColumn'
import { MenuInformation as Information } from './MenuInformation'
import { MenuRow as Row } from './MenuRow'
import {} from './Menu'
import { MenuSvg as Svg } from './MenuSvg'
import { MenuSvgCircle as Circle } from './MenuSvgCircle'

export const MenuStyles = {
  Menu,
  Row,
  Column,
  Information,
  Pane: {
    Item: Pane,
    Inner
  },
  Bar,
  Svg: {
    Item: Svg,
    Circle
  }
}
