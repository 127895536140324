import styled from 'styled-components'
import { breaks, gaps } from 'common/styles/settings'
import { Modifiers as Mods } from 'common/styles/modifiers'

const {
  display: { flex },
  alignItems: { alignCenter },
  justifyContent: { justifyCenter }
} = Mods

export const MenuPaneInner = styled.div`
  ${flex}
  ${alignCenter}


  padding: ${gaps.xl};
  height: inherit;

  ${breaks.md.up} {
    padding: ${gaps.xxl};
  }

  ${breaks.lg.up} {
    padding: ${gaps.xxxl};
  }
`
