import React from 'react'

import { sbClient } from 'hooks/storyblok'
import { I_Image } from 'common/types'

import { useLongPress } from 'hooks/'
import { Button } from 'components/Button'
import { ButtonStyles as BTN } from 'components/Button/styles'
import { Icon } from 'components/Icon'
import { Text } from 'components/Text'
import { Container } from 'components/Container'

import { I_ImagesState } from './'
import { ImagesStyles as I } from './styles'

export interface ImagesItemProps {
  _uid: string
  index: number
  image: I_Image
  imageBig: I_Image
  title: string
  details: any
  imagesState: I_ImagesState
  itemLength: number
  delegateActiveIndex: (index: number) => void
}

export const ImagesItem: React.FC<ImagesItemProps> = (props) => {
  const { _uid: id, index, image, itemLength, imageBig, title, details, imagesState, delegateActiveIndex } = props

  const html = sbClient.richTextResolver.render(details)
  const gestures = useLongPress(() => {
    delegateActiveIndex(index)
  })

  const el = {
    title: (
      <Text type={'span'} modifiers={['textTransform']}>
        {title}
      </Text>
    )
  }

  const renderFigcaptionInformation = () => (
    <>
      <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 16 }}>
        {imageBig && renderButton()}
        <Text type={'p'} modifiers={['xSmall', 'spacingLeft']} spacingLeft={'s'}>
          {el.title}
        </Text>
      </div>
      {details && <I.ImagesItem.Details dangerouslySetInnerHTML={{ __html: html }} />}
    </>
  )

  const renderFigcaption = () => (
    <I.ImagesItem.Figcaption>
      <Container
        modifiers={itemLength > 1 ? undefined : ['spacingTop', 'spacingBottom', 'noPadding']}
        spacingTop={'none'}
        spacingBottom={'none'}
        isFluid={false}
      >
        {renderFigcaptionInformation()}
      </Container>
    </I.ImagesItem.Figcaption>
  )

  const renderButton = () => (
    <Button delegateOnClick={() => delegateActiveIndex(index)} modifiers={['round', 'roundSmall']}>
      <BTN.Label>
        <Icon type={'magnify'} />
      </BTN.Label>
    </Button>
  )

  return (
    <I.ImagesItem.Item modifiers={itemLength > 1 ? ['isGallery'] : undefined} key={id} {...gestures}>
      <I.ImagesItem.Figure>
        <I.ImagesItem.ImageWrapper>
          <I.ImagesItem.Image
            src={`${image.filename}/m/filters:quality(80)`}
            title={image.alt}
            alt={image.alt}
            loading={'lazy'}
          />
        </I.ImagesItem.ImageWrapper>
        {renderFigcaption()}
      </I.ImagesItem.Figure>
    </I.ImagesItem.Item>
  )
}
