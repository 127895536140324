import styled from 'styled-components'
import { pxToRem } from 'common/functions'
import { breaks, gaps } from 'common/styles/settings'

export const MenuBar = styled.span`
  width: 65%;
  height: ${pxToRem(1)};
  background-color: black;

  ${breaks.lg.up} {
    height: ${pxToRem(2)};
  }

  & + & {
    margin-top: ${gaps.s};

    ${breaks.lg.up} {
      margin-top: ${gaps.m};
    }
  }
`
