import { breaks, gaps } from 'common/styles/settings'
import styled from 'styled-components'

import { Button } from './Button'

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${gaps.xl} 0 ${gaps.m};

  & ${Button} + ${Button} {
    margin-top: ${gaps.m};
  }

  ${breaks.md.up} {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    & ${Button} + ${Button} {
      margin-top: initial;
      margin-left: ${gaps.xl};
    }
  }
`
