import React from 'react'
import { motion as m } from 'framer-motion'
import { Container } from 'components/Container'
import { I_Hero } from 'components/Hero'

import { LinkStyles as L } from 'components/Link/styles'
import { HeroStyles as S } from 'components/Hero/styles'
import { Tag } from 'components/Tag'
import { Text } from 'components/Text'

import { TagStyles as T } from 'components/Tag/styles'

import { animation as a } from '../animation'

export interface StageHeaderProps extends Partial<I_Hero> {
  fullSlug: string
  idx: number
}

export const StageHeader: React.FC<StageHeaderProps> = ({
  artist,
  duration,
  headline,
  fullSlug,
  idx,
  isCurrentExhibition
}) => {
  const el = {
    duration: (
      <Text type={'h4'} modifiers={['body', 'spacingBottom']} spacingBottom={'xl'}>
        {isCurrentExhibition && (
          <T.TagWrapper>
            <Tag title={'Current Exhibition'} />
          </T.TagWrapper>
        )}{' '}
        {duration}
      </Text>
    ),
    headline: (
      <Text type={'h2'} modifiers={['subline', 'textTransform']} spacingBottom={'xxxl'}>
        {headline}
      </Text>
    ),
    artist: (
      <Text type={'h3'} modifiers={['subline', 'textTransform']}>
        {artist}
      </Text>
    )
  }

  const renderAnimationWrapper = (el: JSX.Element, idx: number) => (
    <m.div custom={idx} initial={'out'} animate={'in'} exit={'out'} variants={a.text}>
      {el}
    </m.div>
  )

  return (
    <S.HeroHeader style={{ zIndex: 2 }}>
      <S.HeroColumn modifiers={['colorInverted']}>
        <Container spacingTop={'xl'} spacingBottom={'xl'} isFluid={false}>
          {renderAnimationWrapper(el.duration, 1)}
          {renderAnimationWrapper(el.headline, 2)}
          {renderAnimationWrapper(el.artist, 3)}
          {renderAnimationWrapper(<L.Link to={`/${fullSlug}`}>Learn More</L.Link>, 3)}
        </Container>
      </S.HeroColumn>
    </S.HeroHeader>
  )
}
