import styled from 'styled-components'
import { pxToRem } from 'common/functions'
import { Modifiers as Mods } from 'common/styles/modifiers'

const {
  position: { absoluteFull },
  gradient: { whiteToTransparent }
} = Mods

export const AboutCover = styled.div`
  ${absoluteFull}
  ${whiteToTransparent}
  top: initial;
  bottom: 0;
  height: ${pxToRem(200)};
`
