import { Variants } from 'framer-motion'

import { transition } from 'common/constants'

const localTransition = { ...transition, duration: 0.65 }

export const animation: { tileImage: Variants } = {
  tileImage: {
    out: {
      scale: 1,
      transition: localTransition
    },
    in: { scale: 1.1, transition: localTransition }
  }
}
