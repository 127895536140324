import styled, { css } from 'styled-components'

import { applyModifiers, pxToRem } from 'common/functions'
import { gaps, breaks } from 'common/styles/settings'
import { Modifiers as Mods } from 'common/styles/modifiers'

const {
  font: {
    sizes: { xSmall }
  }
} = Mods

export type T_Tag = keyof typeof Modifiers
export interface I_Tag {
  modifiers?: T_Tag[]
}

const Modifiers = {
  xSmall: () => css`
    ${xSmall}
  `,
  inverted: () => css`
    border: ${pxToRem(1)} solid rgba(0, 0, 0, 0.32);

    & div {
      color: black;
    }
  `
}

export const TagWrapper = styled.div`
  display: block;
  margin-bottom: ${gaps.m};

  ${breaks.md.up} {
    display: inline-block;
    margin-bottom: 0;
  }
`

export const Tag = styled.div<I_Tag>`
  display: inline-block;
  margin-right: ${gaps.m};
  padding: ${pxToRem(4)} ${gaps.s} ${pxToRem(2)};
  border: ${pxToRem(1)} solid white;

  ${(props) => applyModifiers(Modifiers, props.modifiers)}
`

export const TagStyles = {
  Tag,
  TagWrapper
}
