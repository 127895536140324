import styled, { css } from 'styled-components'
import { motion as m } from 'framer-motion'

import { applyModifiers } from 'common/functions'

export type T_MenuSvgCircle = keyof typeof Modifiers
export interface I_MenuSvgCircle {
  modifiers?: T_MenuSvgCircle[]
}

const Modifiers = {
  secondary: () => css`
    stroke: whitesmoke;
  `
}

export const MenuSvgCircle = styled(m.path)<I_MenuSvgCircle>`
  stroke: #301240;
  stroke-width: 2;
  fill: transparent;

  ${(props) => applyModifiers(Modifiers, props.modifiers)}
`
