import React, { useContext, useState, useEffect } from 'react'
import { I_Image } from 'common/types'

import { ThemeContext } from 'context'
import { useWindowSize } from 'hooks'
import { pxToRem } from 'common/functions'
import { Container } from 'components/Container'
import { Tag } from 'components/Tag'
import { Text } from 'components/Text'

import { animation as a } from 'components/Stage/animation'

import { HeroStyles as H } from './styles'
import { TagStyles as T } from 'components/Tag/styles'

export interface I_Hero {
  _uid: string
  artist: string
  duration: string
  headline: string
  image: I_Image
  isCurrentExhibition: boolean
}

export interface HeroProps {
  blok: I_Hero
}

export const Hero: React.FC<HeroProps> = ({ blok }) => {
  const { height, width } = useWindowSize()
  const { mainHeaderHeight: mHHeight } = useContext(ThemeContext)
  const { artist, headline, duration, image, isCurrentExhibition } = blok
  const [windowHeight, setWindowHeight] = useState<number>(0)

  useEffect(() => {
    height && setWindowHeight(height)
  }, [])

  useEffect(() => {
    height && setWindowHeight(height)
  }, [width])

  const el = {
    duration: (
      <Text type={'span'} modifiers={['flex', 'alignCenter']}>
        {isCurrentExhibition && (
          <T.TagWrapper>
            <Tag modifiers={['inverted']} title={'Current Exhibition'} />
          </T.TagWrapper>
        )}{' '}
        <Text type={'span'}>{duration}</Text>
      </Text>
    ),
    artist: (
      <Text type={'span'} modifiers={[]}>
        {headline}
      </Text>
    ),
    headline: (
      <Text type={'h2'} modifiers={['headline', 'textTransform', 'spacingTop']} spacingTop={'xl'}>
        {headline}
        <br />
        <Text type={'span'} modifiers={['colorSubtle', 'block']}>
          {artist}
        </Text>
      </Text>
    )
  }

  const renderHeader = () => (
    <H.HeroColumn>
      <Container modifiers={['spacingTop']} spacingTop={'none'} spacingBottom={'none'} isFluid={false}>
        <Text modifiers={['textTransform']}>{el.duration}</Text>
        {el.headline}
      </Container>
    </H.HeroColumn>
  )

  return (
    <H.Hero>
      {image && (
        <H.HeroImgWrapper style={{ height: windowHeight ? pxToRem(windowHeight - mHHeight - 80) : 0 }}>
          <H.HeroImg
            src={`${image.filename}/m/filters:quality(80)`}
            alt={image.alt}
            loading={'eager'}
            initial={'initial'}
            animate={'in'}
            exit={'exit'}
            variants={a.image}
          />
        </H.HeroImgWrapper>
      )}
      <H.HeroHeader>{renderHeader()}</H.HeroHeader>
    </H.Hero>
  )
}
