import styled from 'styled-components'

import { pxToRem } from 'common/functions'
import { gaps } from 'common/styles/settings'

export const TileTag = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  padding: ${gaps.s} ${gaps.m};
  margin: ${gaps.l} 0 0 ${gaps.l};
  background-color: rgba(0, 0, 0, 0.16);

  border: ${pxToRem(1)} solid rgba(255, 255, 255, 0.64);
`
