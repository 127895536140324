import React, { useState, useContext } from 'react'
import { sbClient } from 'hooks/storyblok'
import { motion } from 'framer-motion'

import { OverlayContext } from 'context/'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Icon } from 'components/Icon'
import { Overlay } from 'components/Overlay'
import { Text } from 'components/Text'
import { Vimeo } from 'components/Vimeo'

import { AboutStyles as A } from './styles'
import { ButtonStyles as BTN } from 'components/Button/styles'
import { OverlayStyles as O } from 'components/Overlay/styles'
import { animation as a } from './animation'
import { animation as aImages } from 'components/Images/animation'

interface Props {
  blok: {
    subline: string
    content: any
    showFullText: boolean
    vimeo: string
    documentation: any
  }
}

export const About: React.FC<Props> = ({ blok }) => {
  const { toggleOverlay } = useContext(OverlayContext)
  const { subline, content, vimeo, documentation, showFullText } = blok
  const html = sbClient.richTextResolver.render(content)
  const [state, setState] = useState<{ isExpanded: boolean }>({ isExpanded: false })

  const renderSubline = (subline: string) => (
    <Text type={'h3'} modifiers={['paragraph', 'spacingBottom']} spacingBottom={'xxl'}>
      {subline}
    </Text>
  )

  const renderOverlay = () => {
    return (
      <>
        <Button
          modifiers={['default']}
          delegateOnClick={() => {
            toggleOverlay('content', <Vimeo src={vimeo} />)
          }}
        >
          <BTN.Label>
            <Text type={'span'} modifiers={['xSmall', 'spacingRight']} spacingRight={'l'}>
              Show Exhibition Video
            </Text>
          </BTN.Label>
          <BTN.Button as={'span'} modifiers={['round', 'roundSmall', 'fillWhite']}>
            <BTN.Label>
              <Icon type={'plus'} />
            </BTN.Label>
          </BTN.Button>
        </Button>
      </>
    )
  }

  const renderDocumentationOverlay = () => {
    const html = sbClient.richTextResolver.render(documentation)

    return (
      <>
        <Button
          modifiers={['default']}
          delegateOnClick={() => {
            toggleOverlay(
              'content',
              <O.Overlay.Content>
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </O.Overlay.Content>
            )
          }}
        >
          <BTN.Label>
            <Text type={'span'} modifiers={['xSmall', 'spacingRight']} spacingRight={'l'}>
              Show Documentation
            </Text>
          </BTN.Label>
          <BTN.Button as={'span'} modifiers={['round', 'roundSmall', 'fillWhite']}>
            <Icon type={'plus'} />
          </BTN.Button>
        </Button>
      </>
    )
  }

  return (
    <>
      <Container modifiers={['spacingTop', 'spacingBottom']} spacingTop={'l'} spacingBottom={'xl'} isFluid={false}>
        <A.Wrapper>
          {subline && renderSubline(subline)}
          <A.About
            initial={'closed'}
            animate={showFullText ? 'complete' : state.isExpanded ? 'open' : 'closed'}
            variants={a.about}
            transition={a.transition}
            dangerouslySetInnerHTML={{ __html: `${html}` }}
          />
          {!showFullText && !state.isExpanded && <A.Cover />}
        </A.Wrapper>
        {!showFullText && (
          <>
            <div
              onClick={() => {
                setState({ ...state, isExpanded: !state.isExpanded })
              }}
              style={{ cursor: 'pointer' }}
            >
              <Text
                type={'span'}
                modifiers={['small', 'block', 'underLine', 'spacingTop', 'spacingBottom']}
                spacingTop={'l'}
                spacingBottom={'l'}
              >
                {state.isExpanded ? 'Read Less' : 'Read More'}
              </Text>
            </div>
          </>
        )}
        <BTN.Wrapper>
          {vimeo && renderOverlay()}
          {documentation && renderDocumentationOverlay()}
        </BTN.Wrapper>
      </Container>
    </>
  )
}
