import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { breaks, gaps } from 'common/styles/settings'

import { Modifiers as Mods } from 'common/styles/modifiers'
import { applyModifiers, pxToRem } from 'common/functions'

import { ButtonLabel } from './ButtonLabel'

const {
  font: {
    sizes: { paragraph }
  }
} = Mods

export type T_Button = keyof typeof Modifiers
export interface I_Button {
  modifiers?: T_Button[]
}

const Modifiers = {
  default: () => css`
    display: flex;
    align-items: center;
    padding: ${gaps.s} ${gaps.m} ${gaps.s} ${gaps.xl};
    font-family: 'FKDisplay Regular Alt';
    line-height: 1;
    border-radius: ${pxToRem(32)};
  `,
  round: () => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${pxToRem(32)};
    height: ${pxToRem(32)};
    border-radius: 50%;
    overflow: hidden !important;
    background-color: whitesmoke;
    cursor: pointer;

    ${breaks.md.up} {
      width: ${pxToRem(48)};
      height: ${pxToRem(48)};
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: black;
      opacity: 0;
      transform: scale(0);
      transition: transform 0.15s ease-in-out;
    }

    & svg {
      width: 75%;
      transition: fill 0.25s ease;
    }

    &:hover {
      & ${ButtonLabel} {
        color: white;
      }

      & svg {
        fill: white;
      }

      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }
  `,
  roundSmall: () => css`
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};

    ${breaks.md.up} {
      width: ${pxToRem(32)};
      height: ${pxToRem(32)};
    }
  `,
  fillTransparent: () => css`
    background-color: transparent;
  `,
  fillWhite: () => css`
    background-color: white;
  `,
  fillBlack: () => css`
    background-color: black;

    &:after {
      background-color: whitesmoke;
    }

    &:hover {
      & ${ButtonLabel} {
        color: black;
      }

      & svg {
        fill: black;
      }
    }
  `,
  fillIconWhite: () => css`
    & svg {
      fill: white;
    }
  `,
  outline: () => css`
    border: ${pxToRem(1)} solid black;
  `,
  outlineInverted: () => css`
    border: ${pxToRem(1)} solid white;
    color: white;

    & svg {
      fill: white;
    }
  `,
  shadow: () => css`
    box-shadow: 0 0 ${pxToRem(24)} ${rgba(0, 0, 0, 0.16)};
  `
}

export const Button = styled.button<I_Button>`
  ${paragraph}

  position: relative;
  padding: 0;
  border: 0;
  border-radius: ${pxToRem(12)};
  background-color: whitesmoke;
  line-height: 0;
  color: black;

  &::visited,
  &:focus {
    color: black;
  }

  ${(props) => applyModifiers(Modifiers, props.modifiers)}
`
