import styled from 'styled-components'
import { gaps, breaks } from 'common/styles/settings'

export const MainHeader = styled.div`
  padding: ${gaps.l} 0;

  ${breaks.lg.up} {
    padding: ${gaps.xl} 0;
  }
`
