import styled from 'styled-components'
import { motion as m } from 'framer-motion'

export const StageImg = styled(m.img)`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  min-height: 80vh;
  object-fit: cover;
`
