import { Variants, Transition } from 'framer-motion'
import { ease } from 'common/constants'

const transition: Transition = {
  duration: 0.75,
  ease
}

export const animation: { about: Variants; transition: Transition } = {
  about: {
    open: {
      maxHeight: 3000
    },
    closed: {
      maxHeight: 200
    },
    complete: {
      maxHeight: 'initial'
    }
  },
  transition
}
