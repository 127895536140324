import styled from 'styled-components'
import { gaps, breaks } from 'common/styles/settings'
import { pxToRem } from 'common/functions'

export const HeroHeader = styled.header`
  position: relative;
  z-index: 3;
  top: 0;
  padding-bottom: ${gaps.xxxl};
  padding-top: ${gaps.xxl};

  ${breaks.lg.up} {
    padding-top: ${gaps.xxxxl};
  }
`
