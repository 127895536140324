import { graphql, useStaticQuery } from 'gatsby'

export interface I_AllStoriesQuery {
  storyblokEntry: {
    content: any
  }
}

export function navigationQuery(): I_AllStoriesQuery {
  return useStaticQuery(graphql`
    query NavigationStory {
      storyblokEntry(field_component: { eq: "navigation" }) {
        content
      }
    }
  `)
}
