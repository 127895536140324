import React, { useRef, useEffect } from 'react'

import { ContainerStyles as C } from './styles'
import { TContainer } from './styles/'
import { I_Component } from 'common/types'

export interface ContainerProps extends I_Component {
  isFluid: boolean
  children: React.ReactNode
  modifiers?: TContainer[]
  pushRef?: (element: React.RefObject<HTMLDivElement>) => void
}

export const Container: React.FC<ContainerProps> = ({
  children,
  modifiers,
  isFluid,
  spacingTop,
  spacingBottom,
  pushRef
}) => {
  const refContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    pushRef && pushRef(refContainer)
  }, [refContainer])

  return (
    <C.Container
      ref={refContainer}
      modifiers={modifiers ? modifiers : ['spacingTop', 'spacingBottom']}
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      isFluid={isFluid}
    >
      {children}
    </C.Container>
  )
}
