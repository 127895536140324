import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import { gaps } from 'common/styles/settings'
import { pxToRem } from 'common/functions'

import { Modifiers as Mods } from 'common/styles/modifiers'

const {
  font: {
    transform: { textTransform },
    sizes: { small }
  }
} = Mods

const Link = styled(GatsbyLink)`
  ${small}
  ${textTransform}

  position: relative;
  display: inline-block;
  margin-top: ${gaps.xxl};
  padding: ${gaps.l} ${gaps.xl} ${gaps.m};

  border: ${pxToRem(1)} solid white;
`

export const LinkStyles = {
  Link
}
