import styled from 'styled-components'
import { motion as m } from 'framer-motion'

import { pxToRem } from 'common/functions/'
import { breaks } from 'common/styles/settings'
import { MenuSvgCircle } from './MenuSvgCircle'

export const MenuSvg = styled(m.svg)`
  position: absolute;
  top: 0;
  left: 0;
  width: ${pxToRem(32)};
  height: ${pxToRem(32)};
  overflow: visible;

  ${breaks.lg.up} {
    width: ${pxToRem(48)};
    height: ${pxToRem(48)};
  }

  &:hover ${MenuSvgCircle} {
    fill: transparent;
  }
`
