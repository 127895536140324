import styled from 'styled-components'
import { rgba } from 'polished'

export const HeroCover = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${rgba('#000000', 0.24)};
`
