import styled from 'styled-components'

import { breaks, gaps } from 'common/styles/settings'
import { Modifiers as Mods } from 'common/styles/modifiers'

const {
  font: {
    sizes: { small, xSmall }
  }
} = Mods

export const MenuInformation = styled.div`
  ${breaks.sm.down} {
    margin-right: ${gaps.xl};
  }

  & p {
    ${breaks.sm.down} {
      ${xSmall}
    }
  }
`
