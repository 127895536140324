import styled from 'styled-components'
import { pxToRem } from 'common/functions'
import { gaps } from 'common/styles/settings'

export const TieImage = styled.img`
  width: ${pxToRem(120)};

  & + & {
    margin-left: ${gaps.l};
  }
`
