import { Tile } from './Tile'
import { TileImage as Image } from './TileImage'
import { TileImageWrapper as ImageWrapper } from './TileImageWrapper'
import { TileTag as Tag } from './TileTag'

export const TileStyles = {
  Tile,
  Image,
  ImageWrapper,
  Tag
}
