import React from 'react'
import { Link, navigate } from 'gatsby'

import { I_Image } from 'common/types'
import { Text } from 'components/Text'

import { TileStyles as T } from './styles'
import { animation as a } from './animation'

interface TileProps {
  id: string
  headline: string
  duration: string
  artist: string
  slug: string
  image: I_Image
  isArchived: boolean
  isCurrentExhibition: boolean
}

export const Tile: React.FC<TileProps> = ({
  headline,
  duration,
  artist,
  image,
  id,
  slug,
  isArchived,
  isCurrentExhibition
}) => {
  return (
    <T.Tile
      key={id}
      initial={'out'}
      whileHover={!isArchived ? 'in' : undefined}
      onClick={() => !isArchived && navigate(`/${slug}`)}
    >
      <T.ImageWrapper>
        {isArchived && (
          <T.Tag>
            <Text type={'span'} modifiers={['small', 'colorInverted']}>
              Archive
            </Text>
          </T.Tag>
        )}
        {isCurrentExhibition && (
          <T.Tag>
            <Text type={'span'} modifiers={['small', 'colorInverted']}>
              Current Exhibition
            </Text>
          </T.Tag>
        )}
        {image && <T.Image variants={a.tileImage} src={`${image.filename}/m/filters:quality(60)`} title={image.alt} />}
      </T.ImageWrapper>
      <Text modifiers={['xSmall', 'spacingTop', 'spacingBottom']} spacingTop={'xl'} spacingBottom={'s'}>
        {duration}
      </Text>
      {!isArchived ? (
        <Link to={`/${slug}`}>
          <Text type={'h2'} modifiers={['paragraph', 'textTransform', 'lineHeight']}>
            {headline}
          </Text>
        </Link>
      ) : (
        <Text type={'h2'} modifiers={['paragraph', 'textTransform', 'lineHeight']}>
          {headline}
        </Text>
      )}
      <Text
        type={'h3'}
        modifiers={['paragraph', 'textTransform', 'lineHeight', 'colorSubtle', 'spacingTop', 'spacingBottom']}
        spacingTop={'xs'}
        spacingBottom={'xxl'}
      >
        {artist}
      </Text>
    </T.Tile>
  )
}
