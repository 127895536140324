import styled from 'styled-components'
import { rgba } from 'polished'
import { Modifiers as Mods } from 'common/styles/modifiers'

const {
  position: { absoluteFull }
} = Mods

export const TeaserImageWrapper = styled.div`
  ${absoluteFull}

  &:after {
    ${absoluteFull}

    content: '';
    z-index: 1;
    background-color: ${rgba(0, 0, 0, 0.64)};
  }
`
