import React from 'react'
import SbEditable from 'storyblok-react'
import { DynamicComponent } from '../DynamicComponent'

export interface AssemblerProps {
  blok: any
  lang: string
  location: Location
  isCurrentExhibition: boolean
}

export const Assembler: React.FC<AssemblerProps> = ({ blok, lang, location, isCurrentExhibition }) => {
  const content =
    blok.body &&
    blok.body.map((childBlok: any) => (
      <DynamicComponent
        isCurrentExhibition={isCurrentExhibition}
        blok={{ ...childBlok, isCurrentExhibition }}
        key={childBlok._uid}
        lang={lang}
        location={location}
      />
    ))

  return (
    <SbEditable content={blok} key={blok._uid}>
      {content}
    </SbEditable>
  )
}
