import styled from 'styled-components'
import { HeroHeader } from './HeroHeader'
import { HeroColumn } from './HeroColumn'
import { HeroCover } from './HeroCover'
import { HeroImg } from './HeroImg'
import { HeroImgWrapper } from './HeroImgWrapper'

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: auto;
`

const HeroStyles = {
  Hero,
  HeroHeader,
  HeroColumn,
  HeroCover,
  HeroImg,
  HeroImgWrapper
}

export { HeroStyles }
