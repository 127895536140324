import styled, { css } from 'styled-components'
import { applyModifiers } from 'common/functions'
import { spacers } from 'common/constants'
import { breaks, containerWidth } from 'common/styles/settings'
import { Modifiers as Mods } from 'common/styles/modifiers'
import { T_Spacing } from 'common/types'

const {
  display: { flex },
  alignItems: { alignCenter },
  justifyContent: { spaceBetween },
  spacings: { spacingTop, spacingBottom }
} = Mods

export type TContainer = keyof typeof Modifiers.Container

export interface IContainer {
  modifiers?: TContainer[]
  spacingTop?: T_Spacing
  spacingBottom?: T_Spacing
  isFluid: boolean
}

const Modifiers = {
  Container: {
    flex,
    alignCenter,
    spaceBetween,
    spacingTop,
    spacingBottom,
    flexWrap: () => css`
      flex-wrap: wrap;
    `,
    noPadding: () => css`
      padding-left: 0 !important;
      padding-right: 0 !important;

      ${breaks.md.up} {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      ${breaks.xl.up} {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    `
  }
}

const Container = styled.div<IContainer>`
  margin: 0 auto;
  padding-left: ${spacers.l};
  padding-right: ${spacers.l};
  width: 100%;
  max-width: ${(props) => (props.isFluid ? '100%' : containerWidth.default)};

  ${breaks.md.up} {
    padding-left: ${spacers.xl};
    padding-right: ${spacers.xl};
  }

  ${breaks.xl.up} {
    padding-left: ${spacers.xxl};
    padding-right: ${spacers.xxl};
  }

  ${(props) => applyModifiers(Modifiers.Container, props.modifiers)}
`

const ContainerStyles = {
  Container
}

export { ContainerStyles }
