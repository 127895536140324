import { Variants, Variant } from 'framer-motion'
import { transition, ease } from 'common/constants'

const styles: Variant = {
  position: 'fixed',
  zIndex: 20,
  top: '50%',
  left: '50%',
  x: '-50%',
  y: '-50%',
  width: '100%',
  height: '100%'
}

export const animation: { teaser: Variants; headline: Variants; subline: Variants; name: Variants } = {
  teaser: {
    in: {
      opacity: 1
    },
    out: {
      opacity: 1
    }
  },
  name: {
    in: {
      y: -30,
      transition: {
        ease,
        duration: 0.5
      }
    },
    out: {
      y: 0,
      transition: {
        ease,
        duration: 0.5,
        delay: 0.25
      }
    }
  },
  headline: {
    in: {
      y: -30,
      transition: {
        ease,
        duration: 0.5,
        delay: 0.1
      }
    },
    out: {
      y: 0,
      transition: {
        ease,
        duration: 0.5,
        delay: 0.5
      }
    }
  },
  subline: {
    in: {
      y: -30,
      transition: {
        ease,
        duration: 0.5,
        delay: 0.2
      }
    },
    out: {
      y: 0,
      transition: {
        ease,
        duration: 0.5,
        delay: 0.75
      }
    }
  }
}
