import styled from 'styled-components'
import { applyModifiers } from 'common/functions'
import { gaps, breaks } from 'common/styles/settings'
import { Modifiers as Mods } from 'common/styles/modifiers'

const {
  font: {
    align: { fontAlignRight },
    color: { colorInverted }
  }
} = Mods

type TColumnModifiers = keyof typeof Modifiers.Column

interface IHeroHeaderColumn {
  modifiers?: TColumnModifiers[]
}

const Modifiers = {
  Column: { fontAlignRight, colorInverted }
}

export const HeroColumn = styled.div<IHeroHeaderColumn>`
  flex: 1;
  padding-bottom: ${gaps.m};

  ${breaks.lg.up} {
    padding-bottom: ${gaps.l};
  }

  ${({ modifiers }) => applyModifiers(Modifiers.Column, modifiers)};
`
