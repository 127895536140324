import React, { useState, useEffect, useContext } from 'react'
import { useMotionValue, useTransform } from 'framer-motion'

import { OverlayContext } from 'context'
import { useWindowSize } from 'hooks'
import { Container } from 'components/Container'
import { ImagesItem, ImagesItemProps as I_ImagesItem } from './ImagesItem'
import { Text } from 'components/Text'

import { ImagesStyles as I } from './styles'

interface ImagesProps {
  blok: {
    headline: string
    items: I_ImagesItem[]
  }
}

export interface I_ImagesState {
  length: number
  active: number | null
}

export const Images: React.FC<ImagesProps> = ({ blok }) => {
  const { toggleOverlay } = useContext(OverlayContext)
  const scrollX = useMotionValue(0)
  const { headline, items } = blok
  const { width } = useWindowSize()

  const [imagesState, setImageState] = useState<I_ImagesState>({ active: null, length: items.length })
  const [containerState, setContainerState] = useState<{ x: number; width: number }>({ x: 0, width: 0 })
  const [dragConstraints, setDragConstraints] = useState<number>(0)

  const [refContainerState, setRefContainerState] = useState<React.RefObject<HTMLDivElement> | null>(null)

  useEffect(() => {
    if (refContainerState && refContainerState.current) {
      const { width: outerWidth, x } = refContainerState.current?.getBoundingClientRect()
      let tileWidth: number

      if (items.length > 1) {
        const tileItemWidth = outerWidth / 2
        tileItemWidth < 280 ? (tileWidth = 280) : (tileWidth = tileItemWidth)
      } else {
        tileWidth = outerWidth
      }

      setContainerState(() => ({ ...containerState, width: tileWidth, x }))
    }
  }, [width, refContainerState, items])

  const scrollWidth = useTransform(
    scrollX,
    [0, containerState.x - containerState.width * (imagesState.length - 1)],
    [0, 180]
  )

  const renderImageItem = (imageItem: I_ImagesItem, index: number) => (
    <React.Fragment key={imageItem._uid}>
      {ImagesItem({
        ...imageItem,
        index: index + 1,
        imagesState,
        itemLength: items.length,
        delegateActiveIndex: (aI) => {
          setImageState(() => ({ ...imagesState, active: aI }))
          const imageItem = items[aI - 1]
          toggleOverlay('image', imageItem)
        }
      })}
    </React.Fragment>
  )

  const renderDragWrapper = (children: React.ReactElement) => (
    <I.DragWrapper
      drag={items.length > 1 ? 'x' : false}
      dragConstraints={{
        left: containerState.x - containerState.width * (imagesState.length - 1),
        right: 0
      }}
      whileTap={{ cursor: 'grabbing' }}
      dragElastic={0.2}
      style={{
        x: scrollX,
        left: containerState.x,
        width: imagesState.length > 1 ? containerState.width * imagesState.length : 'auto'
      }}
    >
      {children}
    </I.DragWrapper>
  )

  const castItems = () => items.map((item, index) => renderImageItem(item, index))

  const renderHeadline = () =>
    headline && (
      <Container modifiers={['spacingTop', 'spacingBottom']} spacingTop={'none'} spacingBottom={'none'} isFluid={false}>
        <Text type={'h3'} modifiers={['tertiary', 'colorSubtle', 'textTransform']}>
          {headline}
        </Text>
      </Container>
    )

  return (
    <>
      <I.Images modifiers={['spacingTop', 'spacingBottom']} spacingTop={'xl'} spacingBottom={'xl'}>
        {renderHeadline()}
        {items.length > 1 ? (
          renderDragWrapper(<>{castItems()}</>)
        ) : (
          <Container
            pushRef={(refContainer) => setRefContainerState(refContainer)}
            spacingTop={'none'}
            spacingBottom={'none'}
            isFluid={false}
          >
            {castItems()}
          </Container>
        )}
      </I.Images>
      <Container
        pushRef={(refContainer) => setRefContainerState(refContainer)}
        modifiers={['spacingTop', 'spacingBottom']}
        spacingTop={'xl'}
        spacingBottom={'xxl'}
        isFluid={false}
      >
        {items.length > 1 ? (
          <I.Progess.Item>
            <I.Progess.Indicator style={{ x: scrollWidth }} />
          </I.Progess.Item>
        ) : (
          <span />
        )}
      </Container>
    </>
  )
}
