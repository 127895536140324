import { Variants } from 'framer-motion'
import { transition } from 'common/constants'

const localTransition = { ...transition, duration: 0.85 }

export const animation: { circle: Variants; pane: Variants; paneLink: Variants } = {
  circle: {
    hidden: {
      opacity: 1,
      pathLength: 0
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        duration: 0.5,
        ease: 'easeInOut'
      }
    }
  },
  pane: {
    out: {
      x: '100%',
      transition: localTransition
    },
    in: {
      x: '0%',
      transition: localTransition
    }
  },
  paneLink: {
    out: (index) => ({
      x: '25%',
      opacity: 0,
      transition: { ...localTransition, delay: 0.05 * index }
    }),
    in: (index) => ({
      x: '0%',
      opacity: 1,
      transition: { ...localTransition, delay: 0.05 * index }
    })
  }
}
