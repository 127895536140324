import React, { useLayoutEffect, useContext, useState, useEffect, useRef } from 'react'
import { AnimatePresence } from 'framer-motion'
import { wrap } from 'popmotion'

import { useStoryblok, useWindowSize } from 'hooks'
import { allStoriesQuery } from 'common/queries'
import { I_StoryEntry, I_Story } from 'common/queries/allStories'
import { Button } from 'components/Button'
import { Icon } from 'components/Icon'

import { ThemeContext } from 'context'
import { I_Hero } from 'components/Hero'

import { StageHeader } from './elements'
import { ButtonStyles as BTN } from 'components/Button/styles'
import { StageStyles as S } from './styles'
import { animation as a } from './animation'

export interface StageProps {
  blok: {
    _uid: string
    features: {
      link: {
        id: string
      }
    }[]
  }
  lang: string
  location: Location
}

export const Stage: React.FC<StageProps> = ({ blok, lang, location }) => {
  let storyLinks: I_Story[] = []
  const { features } = blok
  const {
    allStoryblokEntry: { stories }
  } = allStoriesQuery()

  features.map(({ link }) => {
    const hit = stories.find(({ story }) => story.uuid === link.id)
    if (hit) storyLinks.push(hit)
  })

  const { mainHeaderHeight } = useContext(ThemeContext)
  const { height } = useWindowSize()

  const [stageHeight, setStageHeight] = useState<number>(0)
  const [activeState, setActiveState] = useState<number>(0)
  const slideIndex = wrap(0, storyLinks.length, activeState)
  const [isActive, setIsActive] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const [timer, setTimer] = useState(0)
  const countRef = useRef<any | null>(null)

  const handleStart = () => {
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1)
    }, 1000)
  }

  const handleReset = () => {
    // Reset button logic here
    clearInterval(countRef.current)
    setIsActive(false)
    setIsPaused(false)
    setTimer(0)
  }

  const paginate = (newDirection: number) => {
    setActiveState(activeState + newDirection)
    handleReset()
    handleStart()
  }

  useLayoutEffect(() => {
    height && setStageHeight(height - mainHeaderHeight)
  }, [height])

  useEffect(() => {
    handleStart()
  }, [])

  useEffect(() => {
    if (timer > 8) {
      handleReset()
      handleStart()
      activeState < storyLinks.length - 1 ? setActiveState(activeState + 1) : setActiveState(0)
    }
  }, [timer])

  const renderPage = (story: I_StoryEntry, idx: number, activeState: number) => {
    const page = useStoryblok(story, location)
    const { fullSlug } = page
    const { isCurrentExhibition } = page.content
    const { _uid, artist, headline, duration, image } = page.content.body[0] as Partial<I_Hero>

    return (
      idx === activeState && (
        <S.StageElement key={_uid} order={idx}>
          <S.Timeline.Item>
            <S.Timeline.Indicator initial={'initial'} animate={'animate'} variants={a.indicator} />
          </S.Timeline.Item>
          <StageHeader {...{ headline, artist, duration, fullSlug, _uid, idx, isCurrentExhibition }} />
          {image && (
            <S.StageImg
              src={`${image.filename}/m/filters:quality(80)`}
              alt={image.alt}
              initial={'initial'}
              animate={'in'}
              exit={'out'}
              variants={a.image}
            />
          )}
        </S.StageElement>
      )
    )
  }

  return (
    <S.Stage style={{ height: stageHeight }}>
      <AnimatePresence exitBeforeEnter>
        {storyLinks && storyLinks.map(({ story }, index) => renderPage(story, index, activeState))}
      </AnimatePresence>
      <S.Navigation>
        <S.StageElement modifiers={['spaceBetween', 'paddingOnSides']} order={1}>
          <Button
            delegateOnClick={() => {
              activeState > 0 ? paginate(-1) : paginate(storyLinks.length - 1)
            }}
            modifiers={['round', 'fillTransparent', 'fillIconWhite']}
          >
            <BTN.Label>
              <Icon type={'arrow-left'} width={44} height={44} />
            </BTN.Label>
          </Button>
          <Button
            delegateOnClick={() => {
              activeState < storyLinks.length - 1 ? paginate(1) : paginate(-(storyLinks.length - 1))
            }}
            modifiers={['round', 'fillTransparent', 'fillIconWhite']}
          >
            <BTN.Label>
              <Icon type={'arrow-right'} width={44} height={44} />
            </BTN.Label>
          </Button>
        </S.StageElement>
      </S.Navigation>
    </S.Stage>
  )
}
