import * as React from 'react'
import { PopupWidget } from 'react-calendly'
import { ThemeProvider } from 'styled-components'
import { graphql } from 'gatsby'

import { useStoryblok } from 'hooks'
import { theme } from 'common/constants'

import { MainHeader } from 'components/MainHeader'
import { Container } from 'components/Container'
import { Footer } from 'components/Footer'
import { Assembler } from 'components/Assembler'
import { MainLayout } from 'components/MainLayout'
import { Seo } from 'components/Seo'

import 'common/fonts/fonts.css'

import { BodyStyles as B, GlobalStyles as Gs } from 'common/styles/common'

interface PageTemplateProps {
  data: any
  location: Location
}

const PageTemplate: React.FC<PageTemplateProps> = ({ data, location }) => {
  if (data === undefined) return <div></div>
  const story = useStoryblok(data.story, location)

  return (
    <>
      <Seo meta={story.content.Meta} />
      <ThemeProvider theme={{ ...theme }}>
        <Gs />
        <B.Body>
          <MainLayout location={location}>
            <MainHeader />
            <Assembler
              isCurrentExhibition={story.content.isCurrentExhibition}
              blok={story.content}
              lang={story.lang}
              location={location}
            />
          </MainLayout>
          {story.content.isCurrentExhibition && (
            <PopupWidget color={'black'} url={'https://calendly.com/jeanclaudemaier'} text={'PRIVATE VIEWING'} />
          )}
        </B.Body>
        <Footer />
      </ThemeProvider>
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query ($fullSlug: String) {
    story: storyblokEntry(full_slug: { eq: $fullSlug }) {
      lang
      name
      content
      full_slug
      uuid
    }
  }
`
