import styled from 'styled-components'
import { motion as m } from 'framer-motion'
import { breaks, gaps } from 'common/styles/settings'

export const Tile = styled(m.div)`
  width: 100%;
  cursor: pointer;

  ${breaks.sm.up} {
    width: 48%;
    padding-bottom: ${gaps.xxxl};
  }

  ${breaks.lg.up} {
    width: 30%;
  }
`
