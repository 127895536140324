import styled from 'styled-components'
import { pxToRem } from 'common/functions'

import { breaks } from 'common/styles/settings'

export const TileImageWrapper = styled.div`
  position: relative;
  height: ${pxToRem(200)};
  overflow: hidden;

  ${breaks.md.up} {
    height: ${pxToRem(240)};
  }

  ${breaks.lg.up} {
    height: ${pxToRem(320)};
  }
`
