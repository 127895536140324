import styled from 'styled-components'
import { StageElement } from './StageElement'
import { StageImg } from './StageImg'
import { StageNavigation as Navigation } from './StageNavigation'
import { StageTimeline as Timeline } from './StageTimeline'
import { StageTimelineIndicator as Indicator } from './StageTimelineIndicator'

const Stage = styled.div`
  position: relative;
  width: 100vw;
  min-height: 80vh;
  height: inherit;
  overflow: hidden;
  background: black;
`

const StageStyles = {
  Stage,
  StageElement,
  StageImg,
  Navigation,
  Timeline: {
    Item: Timeline,
    Indicator
  }
}

export { StageStyles }
