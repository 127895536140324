import styled from 'styled-components'
import { gaps } from 'common/styles/settings'

export const StageNavigation = styled.div`
  position: absolute;
  z-index: 3;
  left: 0;
  bottom: 64px;
  width: 100%;
  max-width: 100vw;
`
