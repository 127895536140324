import React, { useEffect, useRef, useContext } from 'react'

import { Container } from 'components/Container'
import { LogoStyles as L } from 'common/styles/common'
import { useWindowSize } from 'hooks'
import { ThemeContext } from 'context/'
import { Menu } from 'components/Menu'

import { MainHeaderStyled as MH } from './styles'

interface MainHeaderProps {}

export const MainHeader: React.FC<MainHeaderProps> = () => {
  const { width } = useWindowSize()
  const refMainHeader = useRef<HTMLDivElement>(null)
  const { setMainHeaderHeight } = useContext(ThemeContext)

  const getAndPushMainHeaderHeight = () => {
    if (refMainHeader.current) {
      const { height } = refMainHeader.current.getBoundingClientRect()
      setMainHeaderHeight(height)
    }
  }

  useEffect(() => {
    getAndPushMainHeaderHeight()
  }, [refMainHeader])

  useEffect(() => {
    getAndPushMainHeaderHeight()
  }, [width])

  return (
    <MH.MainHeader ref={refMainHeader}>
      <Container
        modifiers={['flex', 'alignCenter', 'spaceBetween', 'spacingTop', 'spacingBottom']}
        spacingTop={'none'}
        spacingBottom={'none'}
        isFluid={false}
      >
        <a href="/">
          <L.Logo src={`/jcm-logo.png`} title={`Logo gallery JeanClaudeMaier`} />
        </a>
        <Menu />
      </Container>
    </MH.MainHeader>
  )
}
