import { Variants, Variant } from 'framer-motion'
import { transition, ease } from 'common/constants'

const transitionLocal = { ...transition, duration: 0.55 }

export const animation: { image: Variants; text: Variants; indicator: Variants } = {
  indicator: {
    initial: {
      x: '-100%',
      transition: {
        ease: 'linear',
        duration: 8
      }
    },
    animate: {
      x: '0%',
      transition: {
        ease: 'linear',
        duration: 8
      }
    }
  },
  image: {
    initial: {
      scale: 1.05,
      opacity: 0,
      transition: { ...transitionLocal, delay: 0.05 }
    },
    in: {
      scale: 1,
      opacity: 1,
      transition: transitionLocal
    },
    out: {
      opacity: 0,
      transition: transitionLocal
    }
  },
  text: {
    in: (idx) => ({
      opacity: 1,
      y: '0%',
      transition: { ...transitionLocal, delay: 0.05 * idx }
    }),
    out: (idx) => ({
      opacity: 0,
      y: '20%',
      transition: { ...transitionLocal, delay: 0.05 * idx }
    })
  }
}
