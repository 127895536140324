import React from 'react'

import { Link } from 'gatsby'
import { Container } from 'components/Container'

interface FooterProps {}

export const Footer: React.FC<FooterProps> = (props) => {
  return (
    <footer>
      <Container
        modifiers={['flex', 'spaceBetween', 'spacingTop', 'spacingBottom']}
        spacingTop={'xxl'}
        spacingBottom={'xxl'}
        isFluid={true}
      >
        <div>© {new Date().getFullYear()} JeanClaudeMaier</div>
        <div>
          <Link to={'/legal'} title={'legal'}>
            Legal
          </Link>
        </div>
      </Container>
    </footer>
  )
}
